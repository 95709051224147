//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FocusPreview',
  props: {
    focus: Object
  },
  methods: {
    classFocusTitle (nomSociete) {
      switch (nomSociete) {
        case 'Tolus Immo': {
          return 'text-secondary border-secondary'
        }
        case 'CG Courtage': {
          return 'text-primary border-primary'
        }
        default:
          return 'text-dark'
      }
    }
  }
}
